import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import galleryGLB from '../Art/gallery/compressed2.glb';
import artworks from './artworks'; // Adjust the path if needed (e.g., '../artworks')

const LOADING_OVERLAY_ID = 'loadingOverlay';

function showLoadingOverlay() {
  // Prevent duplicate overlays
  if (document.getElementById(LOADING_OVERLAY_ID)) return;

  const loadingDiv = document.createElement('div');
  loadingDiv.id = LOADING_OVERLAY_ID;
  Object.assign(loadingDiv.style, {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    color: 'white',
    fontSize: '24px',
    zIndex: 9999,
  });
  loadingDiv.innerText = 'Loading...';
  document.body.appendChild(loadingDiv);
}

function hideLoadingOverlay() {
  const loadingDiv = document.getElementById(LOADING_OVERLAY_ID);
  if (loadingDiv) {
    document.body.removeChild(loadingDiv);
  }
}

export default function Gallery3D() {
  const canvasRef = useRef(null);

  // Camera position for the HUD
  const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0, z: 0 });

  // Help popup
  const [showHelp, setShowHelp] = useState(true);

  // Desktop movement refs
  const moveForward = useRef(false);
  const moveBackward = useRef(false);
  const rotateLeft = useRef(false);
  const rotateRight = useRef(false);

  // Mobile/touch state/refs
  const startX = useRef(null);
  const startY = useRef(null);

  // State for selected artwork popup
  const [selectedArtwork, setSelectedArtwork] = useState(null);

  // Detect touch device
  const isTouchDevice =
    typeof window !== 'undefined' &&
    ('ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0);

  useEffect(() => {
    // Create and append canvas
    const canvas = document.createElement('canvas');
    canvas.style.width = '100%';
    canvas.style.height = '100vh';
    canvas.style.display = 'block';
    document.body.style.margin = '0';
    document.body.style.overflow = 'hidden';
    document.body.appendChild(canvas);
    canvasRef.current = canvas;

    // Set up renderer
    const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.2;
    renderer.shadowMap.enabled = true;

    // Create the scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x202020);

    // Camera
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(22.92, 2.5, 34.71);

    // Lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 1);
    hemiLight.position.set(0, 10, 0);
    scene.add(hemiLight);

    const dirLight = new THREE.DirectionalLight(0xffffff, 2);
    dirLight.position.set(-12.54, 29.37, -79.41);
    dirLight.castShadow = true;
    scene.add(dirLight);

    // Show overlay while loading
    showLoadingOverlay();

    // Load GLB
    const loader = new GLTFLoader();
    loader.load(
      galleryGLB,
      (gltf) => {
        scene.add(gltf.scene);
        hideLoadingOverlay();
      },
      undefined,
      (error) => {
        console.error('Error loading GLB:', error);
        hideLoadingOverlay();
      }
    );

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // Key mapping
    const keyDownMap = {
      ArrowUp: () => {
        moveForward.current = true;
      },
      ArrowDown: () => {
        moveBackward.current = true;
      },
      ArrowLeft: () => {
        rotateLeft.current = true;
      },
      ArrowRight: () => {
        rotateRight.current = true;
      },
      // Optional WASD
      KeyW: () => {
        moveForward.current = true;
      },
      KeyS: () => {
        moveBackward.current = true;
      },
      KeyA: () => {
        rotateLeft.current = true;
      },
      KeyD: () => {
        rotateRight.current = true;
      },
    };
    const keyUpMap = {
      ArrowUp: () => {
        moveForward.current = false;
      },
      ArrowDown: () => {
        moveBackward.current = false;
      },
      ArrowLeft: () => {
        rotateLeft.current = false;
      },
      ArrowRight: () => {
        rotateRight.current = false;
      },
      KeyW: () => {
        moveForward.current = false;
      },
      KeyS: () => {
        moveBackward.current = false;
      },
      KeyA: () => {
        rotateLeft.current = false;
      },
      KeyD: () => {
        rotateRight.current = false;
      },
    };

    const onKeyDown = (e) => {
      if (typeof keyDownMap[e.code] === 'function') {
        keyDownMap[e.code]();
      }
    };
    const onKeyUp = (e) => {
      if (typeof keyUpMap[e.code] === 'function') {
        keyUpMap[e.code]();
      }
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    // Mobile/touch handlers
    const handleTouchStart = (e) => {
      if (e.touches.length === 1) {
        startX.current = e.touches[0].clientX;
        startY.current = e.touches[0].clientY;
      }
    };

    const handleTouchMove = (e) => {
      if (e.touches.length === 1) {
        const currentX = e.touches[0].clientX;
        const currentY = e.touches[0].clientY;

        const deltaX = currentX - startX.current;
        const deltaY = currentY - startY.current;

        const rotationSpeed = 0.005;
        const movementSpeed = 0.05;

        // Rotate camera by horizontal drag
        camera.rotation.y -= deltaX * rotationSpeed;

        // Move forward/back by vertical drag
        const forwardVector = new THREE.Vector3(0, 0, -1);
        forwardVector.applyEuler(camera.rotation).multiplyScalar(deltaY * movementSpeed);
        camera.position.add(forwardVector);

        startX.current = currentX;
        startY.current = currentY;
      }
    };

    const handleTouchEnd = () => {
      startX.current = null;
      startY.current = null;
    };

    if (isTouchDevice) {
      canvas.addEventListener('touchstart', handleTouchStart);
      canvas.addEventListener('touchmove', handleTouchMove);
      canvas.addEventListener('touchend', handleTouchEnd);
    }

    // Raycasting for clicks/taps
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();

    const handlePointerDown = (event) => {
      event.preventDefault();
      let x, y;
      if (event.touches) {
        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
      } else {
        x = event.clientX;
        y = event.clientY;
      }

      pointer.x = (x / window.innerWidth) * 2 - 1;
      pointer.y = -(y / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(pointer, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const clickedObj = intersects[0].object;
        // Match the clicked object's name with artworks
        if (clickedObj && artworks[clickedObj.name]) {
          setSelectedArtwork(artworks[clickedObj.name]);
        }
      }
    };

    canvas.addEventListener('pointerdown', handlePointerDown);

    // Animation loop
    const clock = new THREE.Clock();
    const animate = () => {
      requestAnimationFrame(animate);

      const delta = clock.getDelta();
      const moveSpeed = 5.0 * delta;
      const rotSpeed = 2.0 * delta;

      if (moveForward.current) {
        const forward = new THREE.Vector3(0, 0, -1).applyEuler(camera.rotation).multiplyScalar(moveSpeed);
        camera.position.add(forward);
      }
      if (moveBackward.current) {
        const backward = new THREE.Vector3(0, 0, 1).applyEuler(camera.rotation).multiplyScalar(moveSpeed);
        camera.position.add(backward);
      }
      if (rotateLeft.current) {
        camera.rotation.y += rotSpeed;
      }
      if (rotateRight.current) {
        camera.rotation.y -= rotSpeed;
      }

      // Lock camera height
      camera.position.y = 2.5;

      // Update HUD
      setCameraPosition({
        x: parseFloat(camera.position.x.toFixed(2)),
        y: parseFloat(camera.position.y.toFixed(2)),
        z: parseFloat(camera.position.z.toFixed(2)),
      });

      renderer.render(scene, camera);
    };
    animate();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);

      if (isTouchDevice) {
        canvas.removeEventListener('touchstart', handleTouchStart);
        canvas.removeEventListener('touchmove', handleTouchMove);
        canvas.removeEventListener('touchend', handleTouchEnd);
      }

      canvas.removeEventListener('pointerdown', handlePointerDown);

      renderer.dispose();
      if (canvas && canvas.parentNode) {
        canvas.parentNode.removeChild(canvas);
      }
      document.body.style.margin = '';
      document.body.style.overflow = '';
    };
  }, [isTouchDevice]);

  // ==== STYLES FOR POPUPS AND HUD ====
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1001,
    padding: '20px',
    boxSizing: 'border-box',
    color: '#fff', // Ensure text is white
    transition: 'all 0.3s ease-in-out',
  };

  const popupContentStyle = {
    maxWidth: '600px',
    width: '90%', // For mobile responsiveness
    maxHeight: '80vh', // Make it scrollable if too tall
    overflowY: 'auto',
    backgroundColor: 'rgba(25, 25, 25, 0.9)',
    padding: '30px 20px',
    borderRadius: '10px',
    textAlign: 'center',
    position: 'relative',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.8)',
    color: '#fff', // White text
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '15px',
    right: '15px',
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'none',
    background: '#555',
    color: '#fff',
    fontSize: '18px',
    width: '34px',
    height: '34px',
    lineHeight: '34px',
    textAlign: 'center',
    transition: 'background 0.2s',
  };

  const headingStyle = {
    marginBottom: '1em',
    fontSize: '1.8rem',
    lineHeight: '1.3',
    color: '#fff', // White text
  };

  const paragraphStyle = {
    marginBottom: '1em',
    fontSize: '1.1rem',
    lineHeight: '1.5',
    color: '#fff', // White text
  };

  const buttonStyle = {
    padding: '12px 24px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
    background: '#666',
    color: '#fff',
    fontSize: '16px',
    marginTop: '1em',
    transition: 'background 0.2s',
  };

  // A small hover effect for the close button
  const handleCloseHover = (e) => {
    e.target.style.background = '#777';
  };
  const handleCloseOut = (e) => {
    e.target.style.background = '#555';
  };

  return (
    <>
      {/* Help / instructions popup */}
      {showHelp && (
        <div style={{ ...overlayStyle, zIndex: 1002 }}>
          <div style={popupContentStyle}>
            <h2 style={headingStyle}>How to Navigate the Gallery</h2>
            <p style={paragraphStyle}>
              <strong>Desktop:</strong> Use <em>arrow keys</em> or <em>W, A, S, D</em> to move/rotate.
              Click on a painting to see more info.
            </p>
            <p style={paragraphStyle}>
              <strong>Mobile/Touch:</strong> Swipe <em>left-right</em> to rotate, and swipe
              <em> up-down</em> to move. Tap on a painting to see more info.
            </p>
            <button onClick={() => setShowHelp(false)} style={buttonStyle}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* Artwork popup if something is selected */}
      {selectedArtwork && (
        <div style={overlayStyle}>
          <div style={popupContentStyle}>
            <button
              style={closeButtonStyle}
              onClick={() => setSelectedArtwork(null)}
              onMouseEnter={handleCloseHover}
              onMouseLeave={handleCloseOut}
            >
              ✕
            </button>

            {/* Artwork info */}
            <img
              src={selectedArtwork.image}
              alt={selectedArtwork.paintingDescription}
              style={{
                maxWidth: '100%',
                marginBottom: '1em',
                borderRadius: '6px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.5)',
              }}
            />
            <h2 style={headingStyle}>{selectedArtwork.paintingDescription}</h2>
            <p style={paragraphStyle}>
              <strong>Artist:</strong> {selectedArtwork.artistName}
            </p>
            <p style={paragraphStyle}>
              <strong>Details:</strong> {selectedArtwork.currentDescription}
            </p>
          </div>
        </div>
      )}

      {/* Camera position HUD */}
      <div
        style={{
          position: 'fixed',
          top: '10px',
          right: '10px',
          padding: '6px 12px',
          backgroundColor: 'rgba(0,0,0,0.7)',
          color: '#fff',
          borderRadius: '5px',
          zIndex: 1000,
          fontSize: '15px',
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.5)',
        }}
      >
        X: {cameraPosition.x} | Y: {cameraPosition.y} | Z: {cameraPosition.z}
      </div>
    </>
  );
}
