const artworks = {
    "Cube067": {
      artistName: "Lana TIKHONOVA",
      paintingDescription: "Moonstone Music",
      currentDescription: "Price: €10,00000 - Size: 200 x 150 cm",
      image: require("../assets/artists/LanaTIKHONOVA/moonstone-music.jpg")  // Adjust the path as necessary
    },
    "Cube052": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Something more than night",
        currentDescription: "Price: €1 200,00 - Size: 80 x 60 cm",
        image: require("../assets/artists/LanaTIKHONOVA/more-than-night.jpg")  // Adjust the path as necessary
      },

      "Cube074": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Checkmate",
        currentDescription: "Price: €1 380,00 - Size: 80 x 60 cm",
        image: require("../assets/artists/LanaTIKHONOVA/checkmate.jpg")  // Adjust the path as necessary
      },

      "Cube042": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Troublemaker",
        currentDescription: "Price: €1 650,00 - Size: 80 x 80 cm",
        image: require("../assets/artists/LanaTIKHONOVA/troublemaker.jpg")  // Adjust the path as necessary
      },

      "Cube068": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Discern the limits of darkness",
        currentDescription: "Price: €2 200,00 - Size: 80 x 80 cm",
        image: require("../assets/artists/LanaTIKHONOVA/limits-of-darkness.jpg")  // Adjust the path as necessary
      },

      "Cube069": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Nox",
        currentDescription: "Price: €5 000,00 - Size: 120 x 120 cm",
        image: require("../assets/artists/LanaTIKHONOVA/nox.jpg")  // Adjust the path as necessary
      },

      "Cube013": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Brown Sugar",
        currentDescription: "Price: €2 000,00 - Size: 80 x 80 cm",
        image: require("../assets/artists/LanaTIKHONOVA/brown-sugar.jpg")  // Adjust the path as necessary
      },

      "Cube063": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Smoke in the mirror of snow",
        currentDescription: "Price: €750,00 - Size: 50 x 50 cm",
        image: require("../assets/artists/LanaTIKHONOVA/smoke-in-the-mirror-of-snow.jpg")  // Adjust the path as necessary
      },

      "Cube070": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "What if",
        currentDescription: "Price: €2 450,00 - Size: 100 x 100 cm",
        image: require("../assets/artists/LanaTIKHONOVA/what-if.jpg")  // Adjust the path as necessary
      },

      "Cube072": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Turn to blues",
        currentDescription: "Price: €750,00 - Size: 50 x 50 cm",
        image: require("../assets/artists/LanaTIKHONOVA/turn-to-blues.jpg")  // Adjust the path as necessary
      },

      "Cube073": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Rose",
        currentDescription: "Price: €2 200,00 - Size: 80 x 80 cm",
        image: require("../assets/artists/LanaTIKHONOVA/rose.jpg")  // Adjust the path as necessary
      },

      "Cube058": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Porcelain cup of the day",
        currentDescription: "Price: €500,00 - Size: 50 x 50 cm",
        image: require("../assets/artists/LanaTIKHONOVA/porcelain-cup-of-the-day.jpg")  // Adjust the path as necessary
      },

      "Cube060": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Crystal silence",
        currentDescription: "Price: €500,00 - Size: 50 x 50 cm",
        image: require("../assets/artists/LanaTIKHONOVA/crystal-silence.jpg")  // Adjust the path as necessary
      },

      "Cube057": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Touch of the moon",
        currentDescription: "Price: €1 250,00 - Size: 60 x 80 cm",
        image: require("../assets/artists/LanaTIKHONOVA/touch-of-the-moon.jpg")  // Adjust the path as necessary
      },

      "Cube017": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Night Overflow",
        currentDescription: "Price: €1 000,00 - Size: 100 x 120 cm",
        image: require("../assets/artists/LanaTIKHONOVA/night-overflow.jpg")  // Adjust the path as necessary
      },

      "Cube034": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "City in Foxy Clothes",
        currentDescription: "Price: €700,00 - Size: 50 x 70 cm",
        image: require("../assets/artists/LanaTIKHONOVA/city-in-foxy-clothes.jpg")  // Adjust the path as necessary
      },

      "Cube056": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "White Moon Sail",
        currentDescription: "Price: €900,00 - Size: 70 x 120 cm",
        image: require("../assets/artists/LanaTIKHONOVA/white-moon-sail.jpg")  // Adjust the path as necessary
      },

      "Cube071": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Unwrap me",
        currentDescription: "Price: €2 450,00 - Size: 100 x 100 cm",
        image: require("../assets/artists/LanaTIKHONOVA/unwrap-me.jpg")  // Adjust the path as necessary
      },

      "Cube019": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Prague Staromestska Water Tower",
        currentDescription: "Price: €1 000,00 - Size: 70 x 100 x 2cm",
        image: require("../assets/artists/LanaTIKHONOVA/prague-staromestska-water-tower.jpg")  // Adjust the path as necessary
      },

      "Cube055": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Colorful side of going nuts",
        currentDescription: "Price: €1 650,00 - Size: 80 x 80 cm",
        image: require("../assets/artists/LanaTIKHONOVA/colorful-side-of-going-nuts.jpg")  // Adjust the path as necessary
      },

      "Cube054": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Dancing with wolves",
        currentDescription: "Price: €1 650,00 - Size: 120 x 120cm",
        image: require("../assets/artists/LanaTIKHONOVA/dancing-with-wolves.jpg")  // Adjust the path as necessary
      },

      "Cube041": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Falling in gold",
        currentDescription: "Price: €4 700,00 - Size: 80 x 120cm",
        image: require("../assets/artists/LanaTIKHONOVA/falling-in-gold.jpg")  // Adjust the path as necessary
      },

      "Cube051": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Enchanting Rain of Colors",
        currentDescription: "Price: Sold - Size: 100 x 75cm",
        image: require("../assets/artists/LanaTIKHONOVA/rain-of-colors.jpg")  // Adjust the path as necessary
      },

      "Cube049": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Redline",
        currentDescription: "Price: €24 000,00 - Size: 250 x 200cm",
        image: require("../assets/artists/LanaTIKHONOVA/redline.jpg")  // Adjust the path as necessary
      },

      "Cube050": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "PERSEPHONE",
        currentDescription: "Price: Sold - Size: 135 x 100cm",
        image: require("../assets/artists/LanaTIKHONOVA/persephone.jpg")  // Adjust the path as necessary
      },

      "Cube044": {
        artistName: "Odile JACENKO",
        paintingDescription: "La Prairie Jaune",
        currentDescription: "Price: €3 000,00 - Size: 193 x 185cm",
        image: require("../assets/artists/OdileJACENKO/La-Prairie-Jaune.png")  // Adjust the path as necessary
      },

      "Cube043": {
        artistName: "Odile JACENKO",
        paintingDescription: "Le cerisier",
        currentDescription: "Price: €3 000,00 - Size: 200 x 150cm",
        image: require("../assets/artists/OdileJACENKO/Le-cerisier.png")  // Adjust the path as necessary
      },

      "Cube080": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les Pèches",
        currentDescription: "Price: €225,00 - Size: 40 x 40cm",
        image: require("../assets/artists/OdileJACENKO/Les-Pèches.png")  // Adjust the path as necessary
      },

      "Cube078": {
        artistName: "Odile JACENKO",
        paintingDescription: "La Clairière",
        currentDescription: "Price: €1 200,00 - Size: 197 x 78cm",
        image: require("../assets/artists/OdileJACENKO/La-Clairière.png")  // Adjust the path as necessary
      },

      "Cube040_primitive0": {
        artistName: "Odile JACENKO",
        paintingDescription: "Le Potiron",
        currentDescription: "Price: €750,00 - Size: 50 x 32cm",
        image: require("../assets/artists/OdileJACENKO/Le-Potiron.png")  // Adjust the path as necessary
      },

      "Cube076": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les Cerises",
        currentDescription: "Price: €900,00 - Size: 64 x 40cm",
        image: require("../assets/artists/OdileJACENKO/Les-Cerises.png")  // Adjust the path as necessary
      },

      "Cube075": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les volets bleus",
        currentDescription: "Price: €3 150,00 - Size: 250 x 200cm",
        image: require("../assets/artists/OdileJACENKO/Les-volets-bleus.png")  // Adjust the path as necessary
      },

      "Cube": {
        artistName: "Odile JACENKO",
        paintingDescription: "Ma Petite Campagne",
        currentDescription: "Price: €3 000,00 - Size: 200 x 163cm",
        image: require("../assets/artists/OdileJACENKO/Ma-Petite-Campagne.png")  // Adjust the path as necessary
      },

      "Cube077": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les Prunes",
        currentDescription: "Price: €225,00 - Size: 40 x 40cm",
        image: require("../assets/artists/OdileJACENKO/Les-Prunes.png")  // Adjust the path as necessary
      },

      "Cube079": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les Navets",
        currentDescription: "Price: €225,00 - Size: 40 x 40cm",
        image: require("../assets/artists/OdileJACENKO/Les-Navets.png")  // Adjust the path as necessary
      },

      "Cube081": {
        artistName: "Naddayella",
        paintingDescription: "Chaleur bleue",
        currentDescription: "Price: €2 000,00 - Size: 50 x 50cm",
        image: require("../assets/artists/Naddayella/Chaleur-bleue.png")  // Adjust the path as necessary
      },

      "Cube082": {
        artistName: "Naddayella",
        paintingDescription: "Paix",
        currentDescription: "Price: €1 000,00 - Size: 29 x 29cm",
        image: require("../assets/artists/Naddayella/Paix.png")  // Adjust the path as necessary
      },

      "Cube083": {
        artistName: "Naddayella",
        paintingDescription: "Liberté, féminité, créativité",
        currentDescription: "Price: €450,00 - Size: 20 x 20cm",
        image: require("../assets/artists/Naddayella/Liberty.png")  // Adjust the path as necessary
      },

      "Cube084": {
        artistName: "Naddayella",
        paintingDescription: "Tranquility",
        currentDescription: "Price: €2 000,00 - Size: 50 x 50cm",
        image: require("../assets/artists/Naddayella/Tranquility.png")  // Adjust the path as necessary
      },

      "Cube085": {
        artistName: "Naddayella",
        paintingDescription: "Explosion fleurie",
        currentDescription: "Price: €1 300,00 - Size: 27 x 21cm",
        image: require("../assets/artists/Naddayella/Explosion-fleurie.png")  // Adjust the path as necessary
      },

      "Cube086": {
        artistName: "Naddayella",
        paintingDescription: "Creeping Berries",
        currentDescription: "Price: €1 500,00 - Size: 30 x 30cm",
        image: require("../assets/artists/Naddayella/Creeping-Berries.png")  // Adjust the path as necessary
      },

      "Cube087": {
        artistName: "Naddayella",
        paintingDescription: "Yellow Aquatic.png",
        currentDescription: "Price: €1 000,00 - Size: 29 x 29cm",
        image: require("../assets/artists/Naddayella/Yellow-Aquatic.png")  // Adjust the path as necessary
      },

      "Cube088": {
        artistName: "Naddayella",
        paintingDescription: "Tempête de soleil",
        currentDescription: "Price: €950,00 - Size: 21 x 29cm",
        image: require("../assets/artists/Naddayella/Tempête-de-soleil.png")  // Adjust the path as necessary
      },

      "Cube089": {
        artistName: "Naddayella",
        paintingDescription: "Goodbye!",
        currentDescription: "Price: €1 500,00 - Size: 30 x 30cm",
        image: require("../assets/artists/Naddayella/Goodbye.png")  // Adjust the path as necessary
      },

      "Cube090": {
        artistName: "Naddayella",
        paintingDescription: "Blue Spring",
        currentDescription: "Price: €800,00 - Size: 21 x 29cm",
        image: require("../assets/artists/Naddayella/Blue-Spring.png")  // Adjust the path as necessary
      },

      "Cube092": {
        artistName: "Naddayella",
        paintingDescription: "Ocean Vegetal",
        currentDescription: "Price: €950,00 - Size: 21 x 29",
        image: require("../assets/artists/Naddayella/Ocean-Vegetal.png")  // Adjust the path as necessary
      },

      "Cube091": {
        artistName: "Naddayella",
        paintingDescription: "Yellow Spring",
        currentDescription: "Price: €450,00 - Size: 21 x 29cm",
        image: require("../assets/artists/Naddayella/Yellow-Spring.png")  // Adjust the path as necessary
      },
    // Add more entries here for other artworks
  };

  
  export default artworks;